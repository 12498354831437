












































































































































.wiki-banner {
  position: relative;
  width: 416px;
  height: 416px;
  background: var(--zs-wiki-banner-bg);
  border-radius: 18px;
  padding: 6px 7px 8px;
  overflow: hidden;
  .swiper-box {
    height: 100%;
    border-radius: 12px;
  }

  .banner-item {
    height: 100%;
    // border: 3px solid;
    // border-radius: 12px;
    box-sizing: border-box;
    overflow: hidden;
    .back-wrap {
      position: relative;
      border-radius: 10px;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
        // background: #fff;
        // box-shadow: 0 0 3px #999;
      }
      .title {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        height: 160px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-left: 18px;
        padding-bottom: 17px;
        border-radius: 8px;
        transform: translateZ(999px);
        .sup {
          font-size: 14px;
          color: #e0e0e0;
          padding-right: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .sub {
          font-size: 40px;
          line-height: 51px;
          color: #fff;
          padding-right: 80px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .video-wrap {
        height: 100%;
        overflow: hidden;
        position: relative;
        border-radius: 8px;
        .video {
          height: 100%;
          border-radius: 8px;
          overflow: hidden;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.swiper-pagination {
  text-align: right;
  position: absolute;
  right: 24px;
  bottom: 27px;

  /deep/ .swiper-pagination-bullet {
    width: 5px;
    height: 5px;
    background: #424242;
    border-radius: 50%;
    opacity: 1;
    margin: 0 3px;
  }

  /deep/ .swiper-pagination-bullet-active {
    background: #a4e4ff;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";