















































.lazy-video {
  position: relative;
  height: 100%;
  .poster {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";