.lazy-video[data-v-22b1523c] {
  position: relative;
  height: 100%;
}
.lazy-video .poster[data-v-22b1523c] {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.lazy-video video[data-v-22b1523c] {
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.ellipsis[data-v-22b1523c] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-22b1523c] {
  *zoom: 1;
}
.clearfix[data-v-22b1523c]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-22b1523c] {
  float: left;
}
.fr[data-v-22b1523c] {
  float: right;
}

.wiki-banner[data-v-02a209bc] {
  position: relative;
  width: 416px;
  height: 416px;
  background: var(--zs-wiki-banner-bg);
  border-radius: 18px;
  padding: 6px 7px 8px;
  overflow: hidden;
}
.wiki-banner .swiper-box[data-v-02a209bc] {
  height: 100%;
  border-radius: 12px;
}
.wiki-banner .banner-item[data-v-02a209bc] {
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}
.wiki-banner .banner-item .back-wrap[data-v-02a209bc] {
  position: relative;
  border-radius: 10px;
  height: 100%;
}
.wiki-banner .banner-item .back-wrap img[data-v-02a209bc] {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 8px;
}
.wiki-banner .banner-item .back-wrap .title[data-v-02a209bc] {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 160px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-left: 18px;
  padding-bottom: 17px;
  border-radius: 8px;
  -webkit-transform: translateZ(999px);
          transform: translateZ(999px);
}
.wiki-banner .banner-item .back-wrap .title .sup[data-v-02a209bc] {
  font-size: 14px;
  color: #e0e0e0;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wiki-banner .banner-item .back-wrap .title .sub[data-v-02a209bc] {
  font-size: 40px;
  line-height: 51px;
  color: #fff;
  padding-right: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wiki-banner .banner-item .back-wrap .video-wrap[data-v-02a209bc] {
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
}
.wiki-banner .banner-item .back-wrap .video-wrap .video[data-v-02a209bc] {
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.swiper-pagination[data-v-02a209bc] {
  text-align: right;
  position: absolute;
  right: 24px;
  bottom: 27px;
}
.swiper-pagination[data-v-02a209bc] .swiper-pagination-bullet {
  width: 5px;
  height: 5px;
  background: #424242;
  border-radius: 50%;
  opacity: 1;
  margin: 0 3px;
}
.swiper-pagination[data-v-02a209bc] .swiper-pagination-bullet-active {
  background: #a4e4ff;
}
.ellipsis[data-v-02a209bc] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-02a209bc] {
  *zoom: 1;
}
.clearfix[data-v-02a209bc]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-02a209bc] {
  float: left;
}
.fr[data-v-02a209bc] {
  float: right;
}


/*# sourceMappingURL=chunk-6e14b488.843f4729.css.map*/