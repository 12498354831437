.wiki-banner {
  position: relative;
  width: 416px;
  height: 416px;
  background: var(--zs-wiki-banner-bg);
  border-radius: 18px;
  padding: 6px 7px 8px;
  overflow: hidden;
}
.wiki-banner .swiper-box {
  height: 100%;
  border-radius: 12px;
}
.wiki-banner .banner-item {
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}
.wiki-banner .banner-item .back-wrap {
  position: relative;
  border-radius: 10px;
  height: 100%;
}
.wiki-banner .banner-item .back-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.wiki-banner .banner-item .back-wrap .title {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 160px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 18px;
  padding-bottom: 17px;
  border-radius: 8px;
  transform: translateZ(999px);
}
.wiki-banner .banner-item .back-wrap .title .sup {
  font-size: 14px;
  color: #e0e0e0;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wiki-banner .banner-item .back-wrap .title .sub {
  font-size: 40px;
  line-height: 51px;
  color: #fff;
  padding-right: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wiki-banner .banner-item .back-wrap .video-wrap {
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
}
.wiki-banner .banner-item .back-wrap .video-wrap .video {
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.swiper-pagination {
  text-align: right;
  position: absolute;
  right: 24px;
  bottom: 27px;
}
.swiper-pagination /deep/ .swiper-pagination-bullet {
  width: 5px;
  height: 5px;
  background: #424242;
  border-radius: 50%;
  opacity: 1;
  margin: 0 3px;
}
.swiper-pagination /deep/ .swiper-pagination-bullet-active {
  background: #a4e4ff;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
